body{
  background-color: #F4F6F7;
  overflow-x: hidden;
  font-family: 'Lato', sans-serif;
font-family: 'Roboto', sans-serif;
box-sizing: border-box;
}
.ant-label{
  background:rgb(244, 246, 247)
}
.ant-table{
  border-radius: 4px;
  box-shadow: rgb(0 0 0 / 6%) 0px 0px 1.6rem;
  overflow: hidden;
}
.ant-table-thead>tr>th{
  background: #fafafacc;
  font-weight: 400;
font-size: 12px;
}
.ant-table-container{
  border-top-left-radius: 4px!important;
  border-top-right-radius: 4px!important;
}
.ant-table-tbody>tr>td {
  border-bottom: 1px solid #f0f0f05e;
}

.ant-layout-header{
  background-color: #ffff;
  line-height: normal;
  height: 72px;
}
.ant-tabs-tab.ant-tabs-tab-disabled{
  display: none;
}

.bg-login{
  background: #65995C;  /* fallback for old browsers */
  display: flex;
  flex-direction: row;
  align-items: stretch;
}



.login-a{
  flex:1;
  padding: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap:16px;
}

.login-a img{
  width:30%;
}

.login-b{
  padding: 32px;
  flex:1;
  background-color: #ffff;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-y: scroll;
}

.card-login{
  border-radius: 8px;
  background-color: #ffff;
  animation: fadeIn 1s ease-in-out 0s 1 alternate;
  min-width: 300px;
  max-width: 400px;
}


.wrap {
  max-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.logo{
  padding: 24px;
  margin-bottom: 32px;
}
.logo img{
  max-width: 100%;
}

.recent td{
  animation: recent 1s ease-in-out 0s 1 alternate;
}

.ant-layout-sider-light{
  box-shadow: rgb(0 0 0 / 6%) 0px -9px 9px;
}

#sider {
  padding-left: 16px;
  padding-right: 16px;
}

#sider .ant-menu-inline, #sider .ant-menu-vertical, #sider .ant-menu-vertical-left{
  border-right:0px;
}
#sider .ant-menu-item svg{
  fill:#65995C;
}
#sider .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #d0d9cc29;
  border-radius: 8px;
}
#sider .ant-menu-item a{
  color:#65995C
}
#sider .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected::after {
  border-right: 0px;
}
.user-avatar{
  box-shadow: rgb(236 236 236 / 45%) 0.8rem -0.8rem 0px inset;
}

@keyframes recent {
  from {background-color: #d0d9cc45;}
  to {background-color: #ffff;}
}

@keyframes fadeIn {
  from {opacity:0;}
  to {opacity: 1;}
}